import React, { useRef } from "react";
import { useState } from "react";
import "./Form.css";
import icon from "../assets/Success.svg";
import icon1 from "../assets/Phone.svg";
import icon2 from "../assets/Mail.svg";
import icon3 from "../assets/Location.svg";
import icon6 from "../assets/Cancel.svg";
import ReactCaptcha from "../Captcha/Captcha";
import axios from "axios";

import img1 from "../assets/Img1.svg";
import img2 from "../assets/Img2.svg";
import img3 from "../assets/Img3.svg";
import { BaseURL } from "../BaseURL";

const Form = () => {
  const captchaRef = useRef();
  const captchaConfig = {
    type: 1,
    length: 6,
    font: {
      size: "40px",
      family: "Sora",
      color: "#2e2e2e",
    },
    back: {
      stroke: "#2e2e2e",
      solid: "#ffffff ",
    },
    cssClass: "custom-captcha",
    handleBlur: (e) => {
      handleBlur(e);
    },
    handleFocus: (e) => {
      handleFocus(e);
    },
    Inputcaptcha: "",
    Resultcaptcha: "1",
  };
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const [isLoading, setIsLoading] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [captchaerror, setCaptchaError] = useState(false);
  const [fielderror, setFieldError] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (
      formValues.Phone_Number[0] === "0" ||
      formValues.Phone_Number.length < 10
    ) {
      setIsLoading(false);
      const econtainer = document.querySelector(".error_text");
      econtainer.classList.add("shake");
      setTimeout(() => {
        econtainer.classList.remove("shake");
      }, 200);
      captchaRef.current.createCaptcha();
      return;
    }
    if (
      [
        formValues.First_Name,
        formValues.Last_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
      ].some((field) => field?.trim() === "")
    ) {
      captchaRef.current.createCaptcha();

      setIsLoading(false);
      // alert("Please fill out required fields.");
      setFieldError(true);
      return;
    }
    if (captchaConfig.Inputcaptcha === captchaConfig.Resultcaptcha) {
      // console.log(captchaConfig.Inputcaptcha);
      // console.log(captchaConfig.Resultcaptcha);

      // const response =
      await axios.post(`${BaseURL}/api/v1/users/ContactForm`, formValues, {
        header: {
          "content type": "application.json",
        },
      });
      // const responseData = response.data;
      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
      captchaRef.current.createCaptcha();
      setIsLoading(false);
      setShowPopup(true);
      setCaptchaError(false);
      setFieldError(false);
      // console.log(responseData)
      // console.log(formValues)
    } else {
      // console.log(captchaConfig.Inputcaptcha);
      // console.log(captchaConfig.Resultcaptcha);
      // alert("Captcha verification failed. Please try again.");
      captchaRef.current.createCaptcha();
      setFieldError(false);
      setCaptchaError(true);
      setIsLoading(false);
    }
  };

  const handleFocus = (e) => {
    const label = e.target.previousElementSibling;
    label.classList.add("Focused");
  };
  const handleBlur = (e) => {
    const label = e.target.previousElementSibling;
    if (!e.target.value) {
      label.classList.remove("Focused");
    }
  };
  return (
    <div className="Form">
      <div className="Form_components">
        <div className="LForm">
          <div className="LForm_Components">
            <h2>
              We will help you&nbsp;with
              <br />
              <span>your requirements,</span>
              <br />
              Tell us about it.
            </h2>
            <div className="Contactus">
              <div className="contact">
                <img src={icon2} alt="Mail" />
                <a
                  href="mailto:contact@okulr.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact@okulr.com
                </a>
              </div>
              <div className="contact Contactno_icon">
                <img src={icon1} alt="Phone" />
                <div className="Contact_No">
                  <a href="tel:6362000130">+91&nbsp;63620&nbsp;00130&emsp;</a>
                  <span>|&emsp;</span>
                  <a href="tel:80 2323 4300">+91&nbsp;80&nbsp;2323&nbsp;4300</a>
                </div>
              </div>
              <div className="contact">
                <img src={icon3} alt="Location" />
                <a
                  href="https://maps.app.goo.gl/wsRuSUohJ45Qk5rU6"
                  target="_blank"
                  rel="noreferrer"
                >
                  Registered Office: Soudhamini, 122, B-Block, III main,
                  Gruhalakshmi Layout II Stage, Kamalanagar, Basaveshwaranagar,
                  Bengaluru 560079 Karnataka India
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="RForm">
          <form>
            <div className="tab_view">
              <div className="Input_Holder">
                <label className="Label">First Name*</label>
                <input
                  type="text"
                  name="First_Name"
                  className="Input_Field"
                  value={formValues.First_Name}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
              <div className="Input_Holder">
                <label className="Label">Last Name*</label>
                <input
                  type="text"
                  name="Last_Name"
                  className="Input_Field2"
                  value={formValues.Last_Name}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="tab_view">
              <div className="error_box">
                <div className="Input_Holder">
                  <label className="Label">Phone Number*</label>
                  <input
                    type="text"
                    name="Phone_Number"
                    className="Input_Field2"
                    value={formValues.Phone_Number}
                    onChange={(event) => {
                      handleChange(event);
                      handleNumChangeM(event);
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {/* <div className="WImage">
                  <img src={icon5} alt="Whatsapp" />
                </div> */}
                </div>
                <p className="error_text">
                  Don’t enter mobile number starting with zero.
                </p>
              </div>
              <div className="Input_Holder">
                <label className="Label">E-Mail*</label>
                <input
                  type="email"
                  name="Email"
                  className="Input_Field"
                  value={formValues.Email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="Input_Holder">
              <label className="Label">Your message*</label>
              <textarea
                name="Message"
                className="Message"
                value={formValues.Message}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="tab_view">
              {/* <div className="Input_Holder">
                <label className="Label"> Captcha*</label>
                <input
                  type="text"
                  name="Captcha"
                  className="Input_Field"
                  value={captchaAnswer}
                  onChange={handleCaptchaChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <div className="Captcha_Re">
                  <div className="Captcha_box">
                    <img src={image} alt="Captcha" />
                    <p>{captcha}</p>
                  </div>
                  <img
                    className="Re"
                    src={icon4}
                    alt="Reload"
                    onClick={handleReloadCaptcha}
                  />
                </div>
              </div> */}
              <div className="error_box">
                <ReactCaptcha config={captchaConfig} ref={captchaRef} />
                {captchaerror && (
                  <p className="error_text">
                    Captcha verification failed. Please try again.
                  </p>
                )}
                {fielderror && (
                  <p className="error_text">Please fill out required fields.</p>
                )}
              </div>
              <div className="Submit_button">
                {isLoading ? (
                  <>
                    <div className="Overlay2" />
                    <div className="Popup1">
                      <div className="Logo_Container">
                        <div className="Image">
                          <div className="Img1">
                            <img className="Spin1" src={img1} alt="" />
                          </div>
                          <div className="Img2">
                            <img className="Spin" src={img2} alt="" />
                          </div>
                          <div className="Img3">
                            <img src={img3} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="Form_Submit"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      Send message
                    </button>
                  </>
                )}
              </div>
            </div>
            {showPopup && (
              <>
                <div className="Overlay2" />
                <div className="Popup">
                  <img
                    src={icon6}
                    alt="Close"
                    className="CloseButton"
                    onClick={() => setShowPopup(false)}
                  />
                  <div className="PContents">
                    <img src={icon} alt="Popup" />
                    <h3>Thank you!</h3>
                    <p>We will reach out to you shortly</p>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
