import React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import image from "../assets/Camera.png";
import icon from "../assets/Scroll.svg";
import icon1 from "../assets/ArrowLeft.svg";
import "./Homepage.css";
import Header from "../Header/Header";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import pat1 from "../assets/Pat2.svg";
import pat2 from "../assets/Pat.svg";
import pat3 from "../assets/Pat1.svg";
import pat4 from "../assets/Pat3.svg";

const Homepage = () => {
  const [showAnimation, SetAnimation] = useState(true);
  const [check, SetCheck] = useState(false);
  const [show, setShow] = useState(0);
  let previousScrollPosition = 0;

  const handleScroll = (event) => {
    if (showAnimation) {
      const Camera = document.querySelector(".Camera");
      if (Camera) {
        // const AI = document.querySelector(".AI_Text");
        Camera.classList.remove("deactive");
        Camera.classList.add("active");
        document.body.style.overflow = "hidden";

        // AI.classList.remove("deactive");
        // AI.classList.add("active");
        setTimeout(() => {
          SetAnimation(false);
        }, 1000);
        setTimeout(() => {
          document.body.style.overflow = "auto";
        }, 3000);
      }
    }

    const currentScrollPosition = document.documentElement.scrollTop;
    const OurProducts = document.querySelector(".Homepage_components2");
    if (OurProducts) {
      if (currentScrollPosition < previousScrollPosition) {
        if (document.documentElement.scrollTop === 0) {
          OurProducts.classList.add("deactive");
          document.body.style.overflow = "hidden";
          setTimeout(() => {
            handleClick();
          }, 900);
          setTimeout(() => {
            document.body.style.overflow = "auto";
          }, 3000);
        }
      }
      previousScrollPosition = currentScrollPosition;
    }
  };
  const handleClick = () => {
    SetAnimation(true);
    SetCheck(true);
    setTimeout(() => {
      SetCheck(false);
      const Camera = document.querySelector(".Camera");
      // const AI = document.querySelector(".AI_Text");
      Camera.classList.add("deactive");
      // setTimeout(() => {
      //   AI.classList.add("deactive");
      // }, 1000);
    }, 900);
  };

  const initialScrollPosition = window.scrollY;

  useEffect(() => {
    if (document.documentElement.scrollTop && initialScrollPosition === 0) {
      SetAnimation(true);
    }
    const Image = document.querySelector(".Image_container");
    if (Image && show === 0) {
      Image.classList.add("show");
      setShow(1);
    }
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("touchmove", handleScroll);
    };
  }, [showAnimation]);

  return (
    <div>
      <Helmet>
        <title>
          AI based Video Analytics, computer vision company in India - Okulr
        </title>
        <meta
          name="description"
          content="Discover cutting-edge intelligent video analytics technology solutions to boost surveillance effectiveness. Utilize AI-powered algorithms for real-time insights, anomaly detection, and security system enhancement. Explore our innovative approach today! India."
        />
        <meta
          name="keyword"
          content="video analytics, video analytics software, cctv analytics, video analytics companies, video analytics cctv, video analytics surveillance, video analytics open source, intelligent video analytics software, video analytics solutions, artificial intelligence video surveillance, cctv analytics software, video streaming analytics, video analytics camera, video analytics system, advanced video analytics, edge video analytics, c, video analytics companies, top video analytics companies, video analytics software companies, video analytics company, best video analytics software, video analytics startups   "
        />
        <link rel="canonical" href="https://www.okulr.com" />
      </Helmet>
      <Header />
      {showAnimation ? (
        <>
          {check && <div className="Overlay1"></div>}

          <div className="Homepage_components1">
            <div className="Home_components">
              <div className="Image_container">
                <img
                  className="Camera"
                  src={image}
                  alt="Camera"
                  loading="lazy"
                />
                {/* <div className="AI_Text">
                <h2>ARTIFICAL INTELLIGENCE</h2>
              </div> */}
              </div>
              <div className="Text_container">
                <h1>
                  Connecting vision to sense,<br></br> making sense of seeing
                </h1>
                <p>
                  Welcome to OKULR! We’re a young team of vision computing geeks
                  who believe that it’s the data that’s important in the video,
                  and not just the video. At Okulr we replicate the amazing
                  connectivity between the eye and the brain to see and
                  interpret the data from what we see, thus making sense of
                  seeing in a camera.
                  {/* A camera can be much more than just recording a video, and
                  people searching the video for incidents or data. We build
                  intelligence in the cameras, enabling it as tool to see,
                  analyse, interpret, and action in the situation it is for an
                  effective decision making. We do this on our AI platform Fovea
                  to give you the best of video analytics and data analytics
                  under one roof. */}
                </p>
              </div>
              <div className="Scroll_container">
                <h3>Scroll to explore</h3>
                <img src={icon} alt="Scroll" onClick={handleScroll} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="Overlay_home"></div>
          <div id="Homepage_components2" className="Homepage_components2">
            <h2>Our Products</h2>
            <div className="Our_products">
              <div className="line_v_container_1">
                <div className="line_v"></div>
              </div>
              <div className="line_h_container_1">
                <div className="line_h"></div>
              </div>
              <div className="dot_container_1">
                <div className="dot"></div>
              </div>
              <div className="Our_products_top">
                <div className="Product">
                  <img className="Pattern1" src={pat1} alt="Network1" />
                  <div className="P_text">
                    <h3>Facesense</h3>
                    <p>
                      Face recognition systems have been widely used today for
                      various needs ranging from people tracking to criminal
                      database management system, street security, and defense
                      applications. Our flagship product Facesense is a simple,
                      yet powerful face recognition tool for your attendance
                      management system. Using Facesense, you can make your
                      existing IP cameras/CCTVs into an attendance management
                      system. Yes! That’s right. Facesense is a subscription
                      model product that.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/face-recognition-technology">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Product">
                  <img className="Pattern4" src={pat4} alt="Network3" />

                  <div className="P_text">
                    <h3>ATES – Automated Traffic Enforcement System</h3>
                    <p>
                      Managing burgeoning traffic today in Indian cities is
                      indeed a gargantuan task for traffic police. With
                      increasing vehicular population, enforcement is of utmost
                      importance to increase road safety, and to save lives
                      considering 4.61 lakhs accidents reported in 2022 in India
                      as per data from MORTH. Using AI to regulate road traffic,
                      increase automated smart enforcement and educate road
                      safety to users is the need of the hour. ATES is our
                      traffic enforcement product designed.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/automated-traffic-enforcement-system">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Our_products_bottom">
                <div className="Product">
                  <img className="Pattern2" src={pat2} alt="Network2" />

                  <div className="P_text">
                    <h3>Seema Suraksha - Perimeter Smart Security System</h3>
                    <p>
                      Large perimeters or boundaries are always a pain in the
                      neck to watch over 24/7. Manpower security with passive
                      cameras are not the perfect tools to keep intruders away
                      from your large boundaries. Whether its high security
                      boundaries or private properties, detecting and stopping
                      intrusion is not easy. Seema Suraksha, our perimeter
                      security enforcement is a well-trained AI tool to guard
                      your large boundary area with its integrated accurate
                      human detection and recognition, multilingual.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/perimeter-smart-security-system">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Product">
                  <img className="Pattern3" src={pat3} alt="Network4" />
                  <div className="P_text">
                    <h3>Vehicle Telematics</h3>
                    <p>
                      Public buses, cargo transportation vehicles, critical
                      material transportation systems, ambulance, oil tankers
                      require added safety measures to ensure road safety,
                      reduce accidents and loss of lives. Monitoring driver to
                      ensure passenger safety is of utmost importance. Thanks to
                      AI, this can be done with ease. Our vehicle telematics
                      product is designed and built on our AI platform to bring
                      in whole new dimension to ensure safety of people and
                      cargo. It is equipped with a dashcam integrated with.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/vehicle-telematics">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Our_products" style={{ marginTop: "100px" }}>
              <div className="line_v_container">
                <div className="line_v"></div>
              </div>
              <div className="line_h_container">
                <div className="line_h"></div>
              </div>
              <div className="dot_container">
                <div className="dot"></div>
              </div>
              <div className="Our_products_top">
                <div className="Product">
                  <img className="Pattern1" src={pat1} alt="Network1" />
                  <div className="P_text">
                    <h3>Mahila Suraksha Safety Islands</h3>
                    <p>
                      Women safety is important for a healthy society around us.
                      Ensuring safety of women and children while on the streets
                      is the core responsibility of the law enforcement. But
                      with vast city areas, it become a difficult task for
                      police to keep an eye on every possible street especially
                      the sensitive areas in the city. Mahila Suraksha safety
                      islands addresses this problem with a punch. Well-
                      designed product meeting the required functionality, these
                      safety islands are independent and autonomous.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/smart-poles">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Product">
                  <img className="Pattern4" src={pat4} alt="Network3" />

                  <div className="P_text">
                    <h3>Suraksha Netra</h3>
                    <p>
                      There are homes, and there are CCTVs installed supposedly
                      to protect these homes. The question is: How on earth
                      these very homes are burgled? Because these CCTVs are
                      brainless forensic devices that only records the video. It
                      cannot make a difference between normal and violation
                      incidents. Most of us are made to believe that just
                      installing CCTVs will secure our homes, while the fact is
                      even these CCTVs and NVR are stolen by burglars who very
                      well know how to deal with these passive devices......{" "}
                    </p>
                    <div className="button_body">
                      <a href="/suraksha-netra">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Our_products_bottom">
                <div className="Product">
                  <img className="Pattern2" src={pat2} alt="Network2" />

                  <div className="P_text">
                    <h3>Ambulance Signal-free corridor</h3>
                    <p>
                      There are traffic jams in almost major cities in India,
                      and this leads to a problem every ambulance faces – Stuck
                      in traffic jams with critical patients onboard. More than
                      70% of the ambulances are stuck in these traffic jams in
                      most of the major cities. WHO says that the chances of
                      life saving increases to over 90% if the patient reaches
                      the hospital within the golden hour, which is indeed a
                      challenge today. Technology can indeed resolve this
                      problem, making a signal-free corridors for the.....
                    </p>
                    <div className="button_body">
                      <a href="/ambulance-signal-free-corridor">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Product">
                  <img className="Pattern3" src={pat3} alt="Network4" />
                  <div className="P_text">
                    <h3>Ecopole - One pole, many Possibilities!</h3>
                    <p>
                      Ecopole is one of innovative and disruptive product in the
                      market catering to smart cities, municipalities, urban
                      landscapes, large residential complex, industrial parks
                      and more. One product encompasses multiple devices
                      integrating as one unified solution giving data to
                      multiple agencies in the city. It is a perfect example of
                      incredible design meeting the required functionality.
                      Equipped with multiple intelligent devices such as AI
                      cameras.....{" "}
                    </p>
                    <div className="button_body">
                      <a href="/smart-pole-solution">
                        <button className="P_button">
                          Know More
                          <img className="Barrow" src={icon1} alt="Arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="AIandML">
            <h3>
              It’s not what we see, but how we see that matters to decide. Let’s
              make sense of seeing.
            </h3>
          </div>
          <Form />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Homepage;
